import $ from "jquery";
import Swiper from "swiper";
import { Navigation } from "swiper/modules";
import SimpleLightbox from "simple-lightbox";

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);

$(() => {
  $(window).on("load", function () {
    conferenceBanner();

    $(".project-gallery a").simpleLightbox();

    helpBlockFunctionality();
    if (window.innerWidth > 450 && $(".products-slider").length > 0) {
      fixProductsSliderBg();
    }
    setTimeout(() => {
      $(".loader").fadeOut(800);
    }, 200);
    setTimeout(() => {
      window.scrollBy(0, 1);
      hashScroll();
    }, 400);
  });

  function hashScroll() {
    const hashArr = [
      "#services-find-out-more",
      "#learn-more",
      "#contact-us",
      "#find-out-more-hero",
      "#challenge",
    ];
    //functionality for scrolling with hash already in url
    const urlHash = window.location.hash;

    let headerOffset = 150;
    if (window.innerWidth < 1024) {
      headerOffset = 80;
    }

    if (urlHash && !hashArr.includes(urlHash)) {
      $("html, body").animate(
        {
          scrollTop: $(urlHash).offset().top - headerOffset,
        },
        1500
      );
    }
  }

  function customScroll(hrefAnchor, scrollEl, offset = 150) {
    $("a[href=" + hrefAnchor + "]").on("click", function (e) {
      let headerOffset = offset;
      if (window.innerWidth < 1024) {
        headerOffset = 80;
      }
      e.preventDefault();
      $("html, body").animate(
        {
          scrollTop: $(scrollEl).offset().top - headerOffset,
        },
        1500
      );
    });
  }

  // Conference Banner Sript
  function conferenceBanner() {
    const banner = document.querySelector("#conf-banner");
    const COOKIE_NAME = "sileo_conf_popup";
    if (banner) {
      // Check if cookie exists
      const cookie = getCookie(COOKIE_NAME);

      if (!cookie) {
        banner.classList.add("show");
        lockScroll();
        $("#conf-banner").click(function () {
          banner.classList.remove("show");
          setCookie(COOKIE_NAME, true);
          unlockScroll();
        });

        $("#conf-banner .conf-content").click(function (event) {
          event.stopPropagation();
        });

        $("#conf-close").click(() => {
          unlockScroll();
          setCookie(COOKIE_NAME, true);
          banner.classList.remove("show");
        });
      }
    }
  }
  function lockScroll() {
    $("body").addClass("stop-scroll");
  }
  function unlockScroll() {
    $("body").removeClass("stop-scroll");
  }
  // Cookie Helper Functions
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  //hotfix for products slider bg overflow
  function fixProductsSliderBg() {
    //left position plus 30px padding
    const sliderPos = $(".products-slider .container").offset().left + 30;
    const bgImg = $(".products-slider .stretch-bg");

    bgImg.css({
      left: sliderPos,
    });
  }

  function helpBlockFunctionality() {
    if ($(".help-block-full").length) {
      const helpBtns = $(".help-list .help-item");
      const helpContent = $(".dynamic-list .dynamic-content");

      //activate first elements
      helpBtns.first().addClass("active");
      helpContent.first().addClass("active");

      helpBtns.each(function () {
        $(this).on("click", function () {
          let currentID = $(this).parent().attr("class");
          helpBtns.removeClass("active");
          helpContent.removeClass("active");
          $(this).addClass("active");

          $(".dynamic-part")
            .find("." + currentID + " .dynamic-content")
            .addClass("active");
          if (window.innerWidth < 450) {
            $("html, body").animate(
              {
                scrollTop: $(".dynamic-list").offset().top - 120,
              },
              1500
            );
          }
        });
      });
    }
  }

  //frontpage productsslider init
  const swiper = new Swiper(".products-slider-el", {
    modules: [Navigation],
    navigation: {
      nextEl: ".next",
      prevEl: ".prev",
    },
    loop: false,
    slidesPerView: 1,
    spaceBetween: 15,
    breakpoints: {
      640: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 2,
      },
      1280: {
        slidesPerView: 3,
      },
    },
  });
  //team slider init
  const teamSlider = new Swiper(".team-slider-el", {
    modules: [Navigation],
    lazy: true,
    navigation: {
      nextEl: ".next1",
      prevEl: ".prev1",
    },
    loop: false,
    slidesPerView: 1,
    spaceBetween: 15,
    breakpoints: {
      500: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
      1450: {
        slidesPerView: 4,
      },
    },
  });
  //reviews slider init
  const reviewsSlider = new Swiper(".reviews-slider-el", {
    modules: [Navigation],
    autoHeight: true,
    navigation: {
      nextEl: ".next2",
      prevEl: ".prev2",
    },
    loop: false,
    slidesPerView: 1,
    spaceBetween: 50,
  });

  const projectGallerySlider = new Swiper(".project-gallery-el", {
    modules: [Navigation],
    autoHeight: true,
    navigation: {
      nextEl: ".next-project",
      prevEl: ".prev-project",
    },
    loop: false,
    breakpoints: {
      500: {
        slidesPerView: 1.5,
      },
      768: {
        slidesPerView: 2.5,
      },
    },
    spaceBetween: 15,
  });

  $("document").ready(() => {
    //scroll functionality for different buttons
    // services find out more
    customScroll("#services-find-out-more", "#services-find-out-more");

    //frontpage hero scroll
    customScroll("#learn-more", ".text-block-with-title");

    //contact us general scroll to the footer
    customScroll("#contact-us", ".block-above-footer");

    //single project btn cta
    customScroll("#challenge", "#content");

    //mobile menu toggle
    function mobileMenuToggle() {
      const menuBtn = $(".menu-toggle");
      menuBtn.on("click", () => {
        $("body").toggleClass("menu-open");
      });
    }
    mobileMenuToggle();

    //different functions for header
    function headerMenuFunctionality() {
      //set block to nav after load for animation to work
      $("#site-header nav").css("display", "block");

      //close the menu after clicking on the anchor link
      $('a[href*="#"]').on("click", function () {
        $("body").removeClass("menu-open");
      });

      //mobile submenu height toggle for animation
      if (window.innerWidth < 1024) {
        $("#site-header .menu-item-has-children").each(function () {
          let baseH = $(this).find("> a").outerHeight() + 30;
          let submenuH = $(this).find(".sub-menu").outerHeight();
          let openH = baseH + submenuH;

          $(this).css("height", "" + baseH + "px");

          $(this).on("click blur", function () {
            $(this).toggleClass("open");
            if ($(this).outerHeight() == baseH) {
              $(this).css("height", "" + openH + "px");
            } else {
              $(this).css("height", "" + baseH + "px");
            }
          });
        });
      }
    }

    headerMenuFunctionality();

    // start hero title functionality
    $(".hero-claims").appendTo(".hero-title > p");
    var vsOpts = {
      $slides: $(".hero-claims .slide"),
      $list: $(".hero-claims .inner"),
      duration: 10,
      lineHeight: 120,
    };

    //options based on screen size
    if (window.innerWidth < 1024) {
      var vsOpts = {
        $slides: $(".hero-claims .slide"),
        $list: $(".hero-claims .inner"),
        duration: 10,
        lineHeight: 75,
      };
    }
    if (window.innerWidth < 578) {
      var vsOpts = {
        $slides: $(".hero-claims .slide"),
        $list: $(".hero-claims .inner"),
        duration: 10,
        lineHeight: 45,
      };
    }

    var vSlide = new TimelineMax({
      paused: true,
      repeat: -1,
    });

    vsOpts.$slides.each(function (i) {
      vSlide.to(vsOpts.$list, vsOpts.duration / vsOpts.$slides.length, {
        y: i * -1 * vsOpts.lineHeight,
        ease: Elastic.easeOut.config(1, 0.4),
      });
    });
    vSlide.play();
    // end hero title functionality

    //toggle scroll class for page header
    $(window).scroll(function () {
      $("#site-header").toggleClass("scrolled", $(window).scrollTop() > 100);
    });
  });
});
